import React, { useState } from 'react';

const Tabs = ({ tabs }) => {
    const [activeTab, setActiveTab] = useState(tabs[0].name);

    return (
        <div>
            <div className="tab-titles">
                {tabs.map(tab => (
                    <button key={tab.name} onClick={() => setActiveTab(tab.name)} className={`${activeTab === tab.name ? 'active' : ''}`}>
                        {tab.label}
                    </button>
                ))}
            </div>
            <div className="tab-content">
                {tabs.map(tab => (
                    activeTab === tab.name && <div key={tab.name}>{tab.content}</div>
                ))}
            </div>
        </div>
    );
};

export default Tabs;