import React, { useState, useEffect } from 'react';
import socketIOClient from 'socket.io-client';
import './App.css';
import grosPoulet from './assets/gros poulet.png';
import confetti from "https://esm.run/canvas-confetti@1";
import 'animate.css/animate.min.css';
import { soundManager } from './utils/sounds';
import Modal from './components/Modal';
import Tabs from './components/Tabs';

const ENDPOINT = 'wss://biskit.fabiangregoire.fr'; //http://localhost:5040 | wss://biskit.fabiangregoire.fr

function App() {
    const [socket] = useState(() => socketIOClient(ENDPOINT, {
        transports: ['websocket'],
        withCredentials: true,
        secure: true,
        reconnectionAttempts: 5,
        timeout: 20000,
        autoConnect: true,
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
    }));

    let nextPenaltyId = 1;
    const defaultRules= [
        "BISKIT: Si la somme des dés fait 7, tous les joueurs doivent dire 'BISKIT' en levant la main devant le front. Le dernier prend une pénalité !",
        "GROS POULET: Le premier joueur de la partie qui fait un 3, devient le gros poulet. Pour le perdre, il devra de nouveau faire un 3. Pendant tout le temps où il est resté le gros poulet, chaque joueur qui fera un 3 donnera une pénalité au gros poulet ! (Attention, si le gros poulet fait un double 3, il le perd puis le regagne :)",
        "DOUBLE: Si un double est lancé, le joueur peut donner le nombre de pénalité correspondant au double en question. Attention, si c'est un double 6, il peut également ajouter une règle !",
        "DOUBLE 1: Attention, si c'est un double 1, le joueur prend la pénalité maximale ! Aucune échapatoire possible :)",
        "SOMME: Si la somme des dés fait 9, le joueur précédent prend une pénalité. Si c'est 10 c'est le joueur en cours, et si c'est 11 c'est le joueur d'après.",
        "DUEL: Si la somme des dés fait 3, le joueur doit choisir un adversaire pour un duel. Le premier lancé sert à determiner le montant de la pénalité en jeu (le dé leplus haut définit cela). Le deuxième lancer sert à départager le gagnant et la perdant. Attention toutefois, si il y a un double, les joueurs doivent relancer jusqu'à se départager, et la somme des pénalités DOUBLE à chaque fois !"
    ]
    const [room, setRoom] = useState('');
    const [isRoomCreated, setIsRoomCreated] = useState(false);
    const [isGameStarted, setIsGameStarted] = useState(false);
    const [diceResult, setDiceResult] = useState([]);
    const [numDice, setNumDice] = useState(2); // Par défaut, lancer 2 dés
    const [isAnimating, setIsAnimating] = useState(false);
    const [isYourTurn, setIsYourTurn] = useState(false);
    const [currentTurnPlayerId, setCurrentTurnPlayerId] = useState('');
    const [currentTurnPlayerName, setCurrentTurnPlayerName] = useState('');
    const [players, setPlayers] = useState([]);
    const [openPlayerOverlay, setOpenPlayerOverlay] = useState(false);
    const [history, setHistory] = useState([]);
    const [openHistoryOverlay, setOpenHistoryOverlay] = useState(false);
    const [rules, setRules] = useState(defaultRules);
    const [openRulesOverlay, setOpenRulesOverlay] = useState(false);
    const [penaltyNotification, setPenaltyNotification] = useState([]);
    const [showJoinModal, setShowJoinModal] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [playerName, setPlayerName] = useState('');
    const [roomCode, setRoomCode] = useState('');
    const [isConnected, setIsConnected] = useState(true);
    const [notification, setNotification] = useState('');
    const [isMuted, setIsMuted] = useState(soundManager.isSoundMuted());
    const [isAppActive, setIsAppActive] = useState(true);
    const [isDuelActive, setIsDuelActive] = useState(false);
    const [duelOpponent, setDuelOpponent] = useState(null);
    const [duelStage, setDuelStage] = useState(null); // 'selecting', 'rolling', null
    const [duelRolls, setDuelRolls] = useState({});
    const [duelPenalties, setDuelPenalties] = useState(0);
    const [duelRound, setDuelRound] = useState(1);
    const [showModal, setShowModal] = useState(false);

    const tabs = [
        {
            name: 'history',
            label: 'History',
            content: (
                <div>
                    <h3>Historique des lancers :</h3>
                    <div id="diceResult">
                        {history.map((record, index) => (
                            <div key={index}>
                                <p>{record.playerName}: </p>
                                <p>{record.diceResults.join(' / ')}</p>
                            </div>
                        ))}
                    </div>
                </div>
            )
        },
        {
            name: 'players',
            label: 'Players',
            content: (
                <div id="players">
                    {players.map((player, index) => (
                        <div key={index} className={player.id === socket.id ? 'active' : ''}>
                            {player.name}
                        </div>
                    ))}
                </div>
            )
        },
        {
            name: 'rules',
            label: 'Rules',
            content: (
                <div id="rules">
                    {rules.map((rule, index) => (
                        <div key={index}>
                            {rule}
                        </div>
                    ))}
                </div>
            )
        }
    ];

    /*const handleInfoButtonClick = () => {
        const openTextElement = document.querySelector('.toggle-text:first-child'); // Select the "Open" text
        const openTextWidth = openTextElement.getBoundingClientRect().width; // Get its width
    
        // Set the translateX value for the "Close" text dynamically
        const closeTextElement = document.querySelector('#info-changing-text.close .toggle-text:last-child');
        closeTextElement.style.transform = `translateX(-${openTextWidth}px)`; // Apply the calculated width
    
        setShowModal(prev => !prev); // Toggle the modal state
    };*/

    // Récupérer ou créer un identifiant unique pour le joueur
    const getOrCreateUserId = () => {
        let userId = localStorage.getItem('userId');
        if (!userId) {
            userId = 'user-' + Math.random().toString(36).substr(2, 9);
            localStorage.setItem('userId', userId);
        }
        return userId;
    };

    const playSoundIfActive = (sound) => {
        if (isAppActive) {
            soundManager.play(sound);
        }
    };

    // Add this function to generate room codes
    const generateRoomCode = () => {
        return Math.random().toString(36).substring(2, 8).toUpperCase();
    };

    const createRoom = () => {
        setShowCreateModal(true);
    };

    const handleCreateSubmit = (playerName) => {
        if (playerName) {
            const roomCode = generateRoomCode();
            socket.emit('createRoom', {
                roomName: roomCode,
                playerName,
                userId: getOrCreateUserId(),
            });
            setRoom(roomCode);
            setShowCreateModal(false);
        }
    };

    const joinRoom = () => {
        setShowJoinModal(true);
    };

    const handleJoinSubmit = (roomCode, playerName) => {
        if (roomCode && playerName) {
            console.log(`Joining room: ${roomCode} as ${playerName}`);
            socket.emit('joinRoom', {
                roomName: roomCode.toUpperCase(),
                playerName,
                userId: getOrCreateUserId(),
            });
            setRoom(roomCode);
            setShowJoinModal(false);
        }
    };

    const rollDice = () => {
        if (!isYourTurn || isAnimating) return;
        
        setIsAnimating(true);

        socket.emit('rollDice', { room, numDice });

        // Keep the button disabled during animation
        setTimeout(() => {
            setIsAnimating(false);
        }, 2800);
    };

    const openHistory = () => {
        setOpenHistoryOverlay(!openHistoryOverlay);
    }

    const openPlayers = () => {
        setOpenPlayerOverlay(!openPlayerOverlay);
    }

    const openRules = () => {
        setOpenRulesOverlay(!openRulesOverlay);
    }

    // Add sound toggle function
    const toggleSound = () => {
        const newMutedState = soundManager.toggleMute();
        setIsMuted(newMutedState);
    };

    const DuelOpponentSelection = () => {  //2nd event triggered // OK //
        const handleOpponentSelect = (opponent) => {
            socket.emit('selectDuelOpponent', {
                room,
                opponent,
                initiator: socket.id
            });
        };

        return (
            <div className="duel-overlay">
                <h2>Select your opponent for the duel!</h2>
                <div className="opponent-list">
                    {players
                        .filter(player => player.id !== socket.id)
                        .map(player => (
                            <button
                                key={player.id}
                                onClick={() => handleOpponentSelect(player.id)}
                                className="opponent-button"
                            >
                                {player.name}
                            </button>
                        ))
                    }
                </div>
            </div>
        );
    };

    const duelRoll = () => {
        console.log('duelRoll triggered');
        if (isDuelActive && duelStage === 'rolling' && isYourTurn) {
            socket.emit('duelRoll', { room });
            console.log('duelRoll emited to server, isduelactive: ', isDuelActive, ', duelStage: ', duelStage, 'isYourturn: ', isYourTurn);
        }
    };



    // Main socket-related effect
    useEffect(() => {
        // Room handlers
        const handleRoomError = (error) => {
            alert(error);
            setShowJoinModal(false);
            setShowCreateModal(false);
        };

        const handleRoomCreated = (roomCode) => {
            setIsRoomCreated(true);
            setIsYourTurn(true);
            setRoom(roomCode);
        };

        const handleStartGame = () => {
            console.log('Game starting...');
            setIsGameStarted(true);
            soundManager.play('GAME_START');
        };

        // Game state and reconnection handlers
        const handleConnect = () => {
            if (room) {
                setTimeout(() => {
                    socket.emit('rejoinRoom', {
                        roomName: room,
                        userId: getOrCreateUserId(),
                        playerName: playerName
                    });
                }, 1000);
            }
            setIsConnected(true);
        };

        const handleDisconnect = () => {
            setIsConnected(false);
        };

        const handleGameState = (state) => {
            if (state.players && state.players.length > 0) {
                setPlayers(state.players);
                setHistory(state.history);
                const currentPlayer = state.players[state.currentTurn];
                setIsYourTurn(currentPlayer.id === socket.id);
                setCurrentTurnPlayerName(currentPlayer.name);
                setIsRoomCreated(true);
                setRoom(state.roomName);
            }
        };

        // Game mechanics handlers
        const handleDiceResult = (data) => {
            setIsAnimating(true);
            if (Array.isArray(data.diceResults)) {
                console.log('Received dice results:', data.diceResults);
                setDiceResult(data.diceResults);
                
                if (data.diceResults.length > 1) {
                    const allSix = data.diceResults.every(result => result === 6);
                    const allOne = data.diceResults.every(result => result === 1);
                    
                    if (allSix) soundManager.play('DOUBLE_6');
                    if (allOne) soundManager.play('DOUBLE_1');
                }
                
                setTimeout(() => {
                    setIsAnimating(false);
                }, 2800);
            } else {
                setDiceResult([]);
                setIsAnimating(false);
            }
        };

        const handleUpdateTurn = (data) => {
            console.log('Turn update received:', data);
            if (isYourTurn && data.playerId !== socket.id) {
                setTimeout(() => {
                    setIsYourTurn(false);
                    setCurrentTurnPlayerId(data.playerId);
                    setCurrentTurnPlayerName(data.playerName);
                }, 3000);
            } else if (data.playerId === socket.id) {
                setTimeout(() => {
                    setIsYourTurn(true);
                    setCurrentTurnPlayerId(data.playerId);
                    setCurrentTurnPlayerName(data.playerName);
                    soundManager.play('TURN_START');
                }, 3000);
            }
        };

        // Special events handlers
        const handleChickenPlayerStatus = (playerName) => {
            const statusElement = document.getElementById('chicken-status');
            statusElement.className = "";
            setTimeout(() => {
                if (statusElement) {
                    statusElement.className = "animate__animated animate__slideInRight";
                    statusElement.textContent = playerName;
                    if (playerName === currentTurnPlayerName) {
                        soundManager.play('CHICKEN');
                    }
                }
            }, 3000);
        };

        const handleChickenPlayerPenalties = ({playerId, penalty}) => {
            const currentPenaltyId = nextPenaltyId;
            nextPenaltyId++;
            
            setTimeout(() => {
                setPenaltyNotification(prevNotifications => {
                    const newNotification = { id: currentPenaltyId, message: penalty, state: true };
                    return [...prevNotifications, newNotification];
                });
            }, 1500);
            
            setTimeout(() => {
                setPenaltyNotification(prevNotifications => 
                    prevNotifications.map(notification =>
                        notification.id === currentPenaltyId
                            ? { ...notification, state: false }
                            : notification
                    )
                );
            }, 5700);
            
            setTimeout(() => {
                setPenaltyNotification(prevNotifications =>
                    prevNotifications.filter(notification => notification.id !== currentPenaltyId)
                );
            }, 6000);
        };

        // Inside your main useEffect
        const handleUpdateHistory = (updatedHistory) => {
            setHistory(updatedHistory);
        };

        const handlePlayerJoined = (players) => {
            console.log('Players updated:', players);
            setPlayers(players);
            playSoundIfActive('GAME_JOIN');
        };

        const handleBiskitEvent = (message) => {
            setIsYourTurn(currentTurnPlayerId === socket.id);
        };

        const handleDoubleEvent = ({playerName, doubleNumber}) => {
            if (doubleNumber === 1 || doubleNumber === 6) {
                confetti({
                    particleCount: 500,
                    spread: 200
                });
                if (doubleNumber === 1) {
                    handleChickenPlayerPenalties({playerId: 99, penalty: `Et c'est la pénalité maximale pour ${playerName} !`});
                } else {
                    handleChickenPlayerPenalties({playerId: 99, penalty: `${playerName} distribue 6 pénalités et peut ajouter une règle de son choix !`});
                    setTimeout(() => {
                        const newRule = prompt('Enter new rule:');
                        setRules(prevRules => [...prevRules, newRule]);
                    }, 2000);
                }
            } else {
                handleChickenPlayerPenalties({playerId: 99, penalty: `${playerName} distribue ${doubleNumber} pénalités !`});
            }
        };

        const handlePlayerReconnected = (playerName) => {
            setNotification(`${playerName} has reconnected`);
            setTimeout(() => setNotification(''), 3000);
        };

        // Add inside your main useEffect
        const handleDuelStart = (data) => { //1st event triggered after rules check // OK //
            setTimeout(() => {
                console.log('Duel started:', data, 'intiator: ', data.initiator, ',initiator.id: ', data.initiator.id);
                if (socket.id === data.initiator) {
                    setIsDuelActive(true);
                    setDuelStage('selecting');
                    playSoundIfActive('DUEL_START');
                }
                console.log('Duel started stage state:', duelStage);
            }, 1500);
        };

        const handleDuelOpponentSelected = (data) => { //3rd event triggered // OK //
            setTimeout(() => {
                console.log('Duel opponent selected:', data);
                setDuelOpponent(data.opponent);
                if (socket.id === data.initiator) {
                    console.log('we in ts');
                    setDuelStage('rolling');
                    setIsYourTurn(true);
                    setNotification('Roll dice for duel!');
                }
                console.log('Current duel stage', duelStage);
            }, 1500);
        };

        const handleDuelRollResult = (data) => {
            console.log('Duel roll result:', data);
            const { diceResults, isFirstRoll, isDouble, penalties, winner, loser, isFinal } = data;
            
            if (isFirstRoll) {
                setTimeout(() => {
                    setDuelPenalties(penalties);
                    setNotification(`First roll: ${penalties} penalties at stake!`);
                    setIsYourTurn(true); // Keep turn for next roll
                }, 1500);
            } else if (isDouble) {
                setTimeout(() => {
                    setDuelPenalties(penalties);
                    setNotification(`Double! Penalties doubled to ${penalties}. Roll again!`);
                    setIsYourTurn(true); // Keep turn for next roll
                }, 1500);
            } else if (isFinal) {
                // Find the winner and loser objects based on their IDs
                const winnerPlayer = players.find(player => player.id === winner);
                const loserPlayer = players.find(player => player.id === loser);

                // Check if both players were found
                if (winnerPlayer && loserPlayer) {
                    handleChickenPlayerPenalties({
                        playerId: loserPlayer.id,
                        penalty: `${winnerPlayer.name} wins the duel! ${loserPlayer.name} takes ${penalties} penalties!`
                    });
                } else {
                    console.error('Winner or loser not found in players array');
                }
                
                // Reset duel state
                setIsDuelActive(false);
                setDuelStage(null);
                setDuelPenalties(0);
                setDuelOpponent(null);
                setIsYourTurn(false);
            }
        };

        // Register all socket event listeners
        socket.on('connect', handleConnect);
        socket.on('disconnect', handleDisconnect);
        socket.on('roomCreated', handleRoomCreated);
        socket.on('startGame', handleStartGame);
        socket.on('gameState', handleGameState);
        socket.on('diceResult', handleDiceResult);
        socket.on('updateTurn', handleUpdateTurn);
        socket.on('updateHistory', handleUpdateHistory);
        socket.on('playerJoined', handlePlayerJoined);
        socket.on('biskit', handleBiskitEvent);
        socket.on('double', handleDoubleEvent);
        socket.on('chickenPlayerStatus', handleChickenPlayerStatus);
        socket.on('chickenPlayerPenalties', handleChickenPlayerPenalties);
        socket.on('roomError', handleRoomError);
        socket.on('playerReconnected', handlePlayerReconnected);
        socket.on('duelStart', handleDuelStart);
        socket.on('duelOpponentSelected', handleDuelOpponentSelected);
        socket.on('duelRollResult', handleDuelRollResult);

        // Cleanup function
        return () => {
            socket.off('connect', handleConnect);
            socket.off('disconnect', handleDisconnect);
            socket.off('roomCreated', handleRoomCreated);
            socket.off('startGame', handleStartGame);
            socket.off('gameState', handleGameState);
            socket.off('diceResult', handleDiceResult);
            socket.off('updateTurn', handleUpdateTurn);
            socket.off('updateHistory', handleUpdateHistory);
            socket.off('playerJoined', handlePlayerJoined);
            socket.off('biskit', handleBiskitEvent);
            socket.off('double', handleDoubleEvent);
            socket.off('chickenPlayerStatus', handleChickenPlayerStatus);
            socket.off('chickenPlayerPenalties', handleChickenPlayerPenalties);
            socket.off('roomError', handleRoomError);
            socket.off('playerReconnected', handlePlayerReconnected);
            socket.off('duelStart', handleDuelStart);
            socket.off('duelOpponentSelected', handleDuelOpponentSelected);
            socket.off('duelRollResult', handleDuelRollResult);
        };
    }, [socket, room, playerName, isYourTurn, currentTurnPlayerId, currentTurnPlayerName, nextPenaltyId, duelStage]);

    // Keep the separate useEffect for visibility and sound
    useEffect(() => {
        const handleVisibilityChange = () => {
            const isVisible = document.visibilityState === 'visible';
            setIsAppActive(isVisible);
            
            if (!isVisible) {
                soundManager.stopAll();
            }
        };

        const handleFocusChange = () => {
            if (!document.hasFocus()) {
                soundManager.stopAll();
            }
        };

        const handlePageHide = () => {
            soundManager.stopAll();
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);
        window.addEventListener('focus', handleFocusChange);
        window.addEventListener('blur', handleFocusChange);
        window.addEventListener('pagehide', handlePageHide);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            window.removeEventListener('focus', handleFocusChange);
            window.removeEventListener('blur', handleFocusChange);
            window.removeEventListener('pagehide', handlePageHide);
        };
    }, []);

    return (
        <div className="App">
            <button 
                className="sound-toggle" 
                onClick={toggleSound}
                title={isMuted ? "Unmute sounds" : "Mute sounds"}
            >
                {isMuted ? '🔇' : '🔊'}
            </button>
            {!isRoomCreated && (
                <div style={{textAlign: 'center'}}>
                    <a href="#" className="room-buttons" onClick={createRoom}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Create Room
                    </a>
                    <a href="#" className="room-buttons" onClick={joinRoom}>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Join Room
                    </a>
                    
                    {showCreateModal && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Create New Game</h2>
                                <input 
                                    type="text" 
                                    placeholder="Your Name" 
                                    onChange={(e) => setPlayerName(e.target.value)}
                                    value={playerName}
                                />
                                <button onClick={() => handleCreateSubmit(playerName)}>Create</button>
                                <button onClick={() => {
                                    setShowCreateModal(false);
                                    setPlayerName('');
                                }}>Cancel</button>
                            </div>
                        </div>
                    )}
                    
                    {showJoinModal && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Join Game</h2>
                                <input 
                                    type="text" 
                                    placeholder="Room Code" 
                                    onChange={(e) => setRoomCode(e.target.value)}
                                    value={roomCode}
                                />
                                <input 
                                    type="text" 
                                    placeholder="Your Name" 
                                    onChange={(e) => setPlayerName(e.target.value)}
                                    value={playerName}
                                />
                                <button onClick={() => handleJoinSubmit(roomCode, playerName)}>Join</button>
                                <button onClick={() => {
                                    setShowJoinModal(false);
                                    setRoomCode('');
                                    setPlayerName('');
                                }}>Cancel</button>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {isRoomCreated && !isGameStarted && (
                <div>
                    <h2>Room Code: {room}</h2>
                    <p>Share this code with your friends to join!</p>
                    <p>Waiting for other players to join...</p>
                </div>
            )}
            {isGameStarted && (
                <div>
                    <div id="info-button" onClick={() => setShowModal(prev => !prev)}>
                        <div id="info-changing-text" className={`${showModal ? 'close' : 'open'}`}>
                            <span className="toggle-text">Ouvrir</span>
                            <span className="toggle-text">Fermer</span>
                        </div>
                        <div id="info-fix-text">Info</div>
                    </div>
                    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                        <Tabs tabs={tabs} />
                    </Modal>

                    <div id="chicken">
                        <img src={grosPoulet} alt="Gros Poulet"></img>
                        <p id="chicken-status">Vacant</p>
                    </div>
                    <div className="current-player">Tour en cours: <br></br>{currentTurnPlayerName}</div>
                    <div id="dice-container">
                        {Array.isArray(diceResult) && diceResult.map((result, index) => (
                            <div key={index} className={`dice dice${index + 1} ${isAnimating ? 'animate' : ''}`} data-side={result}>
                                {[1, 2, 3, 4, 5, 6].map(side => (
                                    <div key={side} className={`sides side-${side} ${side === result ? 'active' : ''}`}>
                                        {[...Array(side)].map((_, dotIndex) => (
                                            <span key={dotIndex} className={`dot dot-${dotIndex + 1}`}></span>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    <button id="rollDice" onClick={rollDice} disabled={!isYourTurn} className={isYourTurn?'active':''}>JOUER</button>
                    <div style={{display: 'none'}}>
                        <label htmlFor="numDice">Number of Dices:</label>
                        <input
                            type="number"
                            id="numDice"
                            value={numDice}
                            onChange={(e) => setNumDice(Number(e.target.value))}
                            min="1"
                            max="2" 
                            disabled={!isYourTurn}
                        />
                    </div>
                    <div id="penaltyNotification" className= {penaltyNotification.length > 0 ? 'reveal' : 'hide'}>
                        {penaltyNotification.map((notification) => (
                            <div key={notification.id} id="singleNotification" className={notification.state ? 'active' : 'inactive'}>
                                {notification.message}
                            </div>
                        ))}
                    </div>
                    {isDuelActive && duelStage === 'selecting' && <DuelOpponentSelection />}
                    {isDuelActive && duelStage === 'rolling' && (
                        <div className="duel-roll">
                            <h2>Duel Roll!</h2>
                            <button onClick={duelRoll} disabled={!isYourTurn}>Roll Dice</button>
                        </div>
                    )}
                </div>
            )}
            {!isConnected && (
                <div className="connection-warning">
                    Reconnecting...
                </div>
            )}
            {notification && (
                <div className="notification">
                    {notification}
                </div>
            )}
        </div>
    );
}

export default App;