// Import sound files
import double6Sound from '../assets/sounds/double-6.mp3';
import double1Sound from '../assets/sounds/double-1.mp3';
import turnStartSound from '../assets/sounds/Turn-start.mp3';
import chickenSound from '../assets/sounds/chicken.mp3';
import playerJoinedSound from '../assets/sounds/Player-joined.mp3';
import gameStartSound from '../assets/sounds/game-start.mp3';

// Define all game sounds
const SOUNDS = {
    DOUBLE_6: double6Sound,
    DOUBLE_1: double1Sound,
    TURN_START: turnStartSound,
    CHICKEN: chickenSound,
    GAME_JOIN: playerJoinedSound,
    GAME_START: gameStartSound
};

// Create a sound manager class
class SoundManager {
    constructor() {
        this.sounds = {};
        this.isMuted = localStorage.getItem('isMuted') === 'true';
        
        // Pre-load all sounds
        Object.entries(SOUNDS).forEach(([key, path]) => {
            this.sounds[key] = new Audio(path);
        });
    }

    play(soundName) {
        if (!this.isMuted && this.sounds[soundName]) {
            // Check if audio context is suspended (common on mobile)
            if (this.sounds[soundName].context && 
                this.sounds[soundName].context.state === 'suspended') {
                this.sounds[soundName].context.resume();
            }
            
            // Stop any currently playing sounds
            this.stopAll();
            
            // Play the new sound
            this.sounds[soundName].play().catch(error => {
                console.log('Sound play prevented:', error);
            });
        }
    }

    stop(soundName) {
        if (this.sounds[soundName]) {
            this.sounds[soundName].pause();
            this.sounds[soundName].currentTime = 0;
        }
    }

    toggleMute() {
        this.isMuted = !this.isMuted;
        localStorage.setItem('isMuted', this.isMuted);
        
        if (this.isMuted) {
            Object.values(this.sounds).forEach(sound => {
                sound.pause();
                sound.currentTime = 0;
            });
        }
        
        return this.isMuted;
    }

    isSoundMuted() {
        return this.isMuted;
    }

    stopAll() {
        Object.values(this.sounds).forEach(sound => {
            sound.pause();
            sound.currentTime = 0;
        });
    }
}

export const soundManager = new SoundManager();